
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































.vis-info,
[class*='vis-info--'] {
  overflow: hidden;
  width: 100vw;
  max-width: 86rem;
  min-height: 100%;
  padding: 5rem 2rem;
  background: $white;

  @include mq(m) {
    padding: 5rem;
  }
}

.vis-info__inner {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  .is-ready & {
    visibility: visible;
    opacity: 1;
  }
}
